import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import image1 from '../images/Home_page_1.jpg';
import image2 from '../images/crt-3.jpg';
import image3 from '../images/eylet-curtains.jpg';
import image4 from '../images/crt-2.jpg';
import image5 from '../images/kids-room.jpg';



function Cards() {
  return (
    <div className='cards'>
      <h1>"Curtain Stiching starting from 100 rupees per panal "</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src={image2}
              text='"A visual poetry of textures and hues, our curtains redefine charm."'
              label='Sheer Pleated Curtains '
            //   path='/services'
            />
            <CardItem
              src={image4}
              text='"Experience a symphony of sleep with our exquisite blackout curtain. Blackout blocks 100% sun light" '
              label='Blackout Pleated Curtains '
            //   path='/products'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={image3}
              text='"A visual poetry of textures and hues, our curtains redefine charm."'
              label='Eyelet Curtains '
            //   path='/services'
            />
            <CardItem
              src={image5}
              text='"Experience a symphony of sleep with our exquisite blackout curtain. Blackout blocks 100% sun light" '
              label='Kids Room Blinds '
            //   path='/products'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={image1}
              text='"Picture-perfect curtains that frame luxury within every thread."'
              label='Customized Curtains'
            //   path='/products'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;