import React from 'react';
import CardItem from './CardItem';
import { useNavigate } from 'react-router-dom';
import image1 from '../images/Website_Images/Curtain_stiching.jpeg';
import image2 from '../images/Website_Images/Hardware.jpeg';
import image3 from '../images/Website_Images/Track_installation.jpeg';
import image4 from '../images/Website_Images/Curtain_reparing.jpeg';

function Cards() {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  return (
    <div className='cards'>
      <h1>Expertise In</h1>
      <h1>Curtain Stiching | Hardware | Track Installation | Curtain Repairing</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={image1}
              text='Beautiful curtains stitched just for you! Rates starting from 100 rupees per panel. Elevate your space with our craftsmanship.'
              label='Curtains Stitching'
              path='/Curtain'
              onClick={() => handleClick('/Curtain')}
            />
            <CardItem
              src={image2}
              text='Upgrade your curtains with our quality tracks and rods – now at an unbeatable price of 120 rupees per running feet.'
              label='Hardware'
              path='/Sofa'
              onClick={() => handleClick('/Sofa')}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={image3}
              text='Transform your space with our professional track installation services, available at a starting price of only 200 rupees per window'
              label='Track Installation'
              path='/Bedroom'
              onClick={() => handleClick('/Bedroom')}
            />
            <CardItem
              src={image4}
              text='Curtain Repairing starts only at 100 rupees per panal'
              label='Curtain Repairing'
              path='/Corporate'
              onClick={() => handleClick('/Corporate')}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
