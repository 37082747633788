import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Bedroom from "./pages/Bedroom";
import Sofa  from "./pages/Sofa";
import Corporate from "./pages/Corporate";
import Curtain from "./pages/Curtain";
import Admin_Login from "./pages/Admin_Login";
import Customer_data from "./pages/Customer_data";
import Pagenotfound from "./pages/Pagenotfound";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/Bedroom" element={<Bedroom />} />
          <Route path="/Sofa" element={<Sofa />} />
          <Route path="/Corporate" element={<Corporate />} />
          <Route path="/Curtain" element={<Curtain />} />
          <Route path="/Admin" element={<Admin_Login />} />
          <Route path="/Customer" element={<Customer_data />} />
          <Route path="*" element={<Pagenotfound />} />
          

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
