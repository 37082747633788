import React, { useState } from 'react';
import { Box, TextField, Alert } from '@mui/material';
import { styled } from '@mui/system';
import backgroundImageMobile from '../images/Home_page_1.jpg';
import backgroundImageDesktop from '../images/Home_page_2.jpg';
import { useNavigate } from 'react-router-dom';
import Layout from "./../components/Layout/Layout";
import { Button } from '../components/Button';


const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url(${backgroundImageMobile});
    background-size: cover;
    transition: background-image 0.3s ease;

    @media (min-width: 600px) {
      background-image: url(${backgroundImageDesktop});
    }

    & > * {
      margin: ${theme.spacing(4)};
    }
  `
);

const StyledTextField = styled(TextField)(
  ({ theme }) => `
    background-color: #fff;
  `
);

const LoginPage = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    let nm = 'Krishna';
    let ps = 'Krishna_Furnishing';

    if (e.target.adminname.value === nm && e.target.password.value === ps) {
      navigate('/Customer');
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Hide the alert after 3 seconds (3000 milliseconds)
    }
  };

  return (
    <Layout>
      <StyledBox>
        {showAlert && (
          <Alert severity="error">Password or Username is incorrect</Alert>
        )}
        <form onSubmit={handleSubmit}>
          <StyledTextField
            label="Admin Name"
            variant="filled"
            name="adminname"
            fullWidth
            InputProps={{ style: { backgroundColor: '#fff' } }}
          />
          <StyledTextField
            label="Password"
            variant="filled"
            name="password"
            type="password"
            fullWidth
            InputProps={{ style: { backgroundColor: '#fff' } }}
          />
          <Button
            className='btns'
            type="submit"
            buttonStyle='btn--primaryy'
            buttonSize='btn--large'
          >
            Submit <i className='far fa-play-circle' />
          </Button>
        </form>
      </StyledBox>
    </Layout>
  );
};

export default LoginPage;
