import React from 'react';
import '../../src/App.css';
import { Button } from './Button';
import './Herothree.css';
import Slider from './Slider';
import vedio from '../images/Home_page_vedio.mp4';
import {  useNavigate,NavLink } from "react-router-dom";
import image1 from '../images/corparte-dcr.jpg';

// import Contact from './../pages/Contact';



function HeroSection() {
  const navigate = useNavigate();
  const handleContactClick = () => {
    // Replace [8125751029] with the actual phone number
    const phoneNumber = +918309109361;
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open WhatsApp chat
    window.location.href = whatsappLink;

    // Alternatively, if you want to navigate to a contact page using react-router-dom
    // history.push('/contact');
  };
  return (
    <div className='hero-container-three'  >
      <image1/>
      <h1>TRACK INSTALLATION</h1>
      <p>Transforming Spaces Elevating Comfort</p>
      <div className='hero-btns'>
      <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={handleContactClick}
        >
          CONTACT US
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;