import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useMediaQuery } from '@mui/material';
import image1 from '../images/eylet-curtains.jpg';
import image2 from '../images/bed_room_decore.jpg';
import image3 from '../images/bedroom-decr.jpg';
import image4 from '../images/crt-2.jpg';
import image5 from '../images/crt-3.jpg';
import image6 from '../images/platted-curtains.jpg';
import image7 from '../images/zbra-blinds-2.jpg';
import image8 from '../images/zebra-blinds-2.jpg';
import image9 from '../images/zebra-blinds.jpg';
import image10 from '../images/Single-sofa.jpg';
import image11 from '../images/roller-blinds.jpg';
import image12 from '../images/kids-room.jpg';

export default function TitlebarBelowMasonryImageList() {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ margin: '10%', overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={isMobile ? 1 : 3} gap={8}>
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.img}?w=248&fit=crop&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{ width: '100%', height: 'auto' }}
            />
            <ImageListItemBar
              position="below"
              title={item.author}
              sx={{ fontSize: isMobile ? 'small' : 'inherit' }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: image1,
    title: 'Bed',
    author: 'Eyelet Curtains',
  },
  {
    img: image2,
    title: 'Books',
    author: 'Black Out Curtains',
  },
  {
    img: image3,
    title: 'Sink',
    author: 'Custom Made Curtains',
  },
  {
    img: image4,
    title: 'Kitchen',
    author: 'Sheer and Main curtains',
  },
  {
    img: image5,
    title: 'Blinds',
    author: 'Sheer Curtains',
  },
  {
    img: image6,
    title: 'Chairs',
    author: 'Platted Curtains',
  },
  {
    img: image7,
    title: 'Laptop',
    author: 'Vertical Blind',
  },
  {
    img: image8,
    title: 'Doors',
    author: 'Wooden Blind',
  },
  {
    img: image9,
    title: 'Coffee',
    author: 'Roller Blind',
  },
  {
    img: image10,
    title: 'Storage',
    author: 'window Blind',
  },
  {
    img: image11,
    title: 'Candle',
    author: 'Roller Blind',
  },
  {
    img: image12,
    title: 'Coffee table',
    author: 'Kids Room Blind',
  },
];
