import React, { useState } from "react";
import Layout from "./../components/Layout/Layout";
import { Button } from '../components/Button';
import Imag from "../components/Image_Card";
import { Box, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';

const Contact = () => {
  const [form, setForm] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const handleForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const response = await fetch('http://localhost:8080/demo', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();
    setSubmitting(false);

    if (response.ok) {
      // Show success alert
      alert("We will reach out soon.");
      // Reset form fields
      setForm({});
    } else {
      // Handle error if needed
      alert("Submission failed. Please try again.");
    }
  }

  return (
    <Layout>
      <Imag/>
      <Box sx={{ my: 5, ml: 10, "& h4": { fontWeight: "bold", mb: 2 } }}>
        <Typography variant="h4">Free In-Home Consultation</Typography>
        <p style={{ textAlign: 'left' }}>
  We value your trust in The Curtain Company, and our executive is excited to collaborate with you on enhancing the aesthetics and comfort of your living space. We eagerly anticipate the opportunity to bring your decor dreams to life.
</p>

      </Box>
      <Box
        sx={{
          m: 3,
          width: "500px",
          ml: 10,
          "@media (max-width:500px)": {
            width: "200px",
          },
        }}
      >
        <form onSubmit={handleSubmit}>
          <TextField fullWidth label="Name" id="fullWidth" name="username" onChange={handleForm} value={form.username || ''} variant="outlined" sx={{ mb: 2 }} />
          <TextField fullWidth label="Phone Number" id="fullWidth" name="phone" onChange={handleForm} value={form.phone || ''} variant="outlined" sx={{ mb: 2 }} />
          <TextField fullWidth label="Subject" id="fullWidth" name="Subject" onChange={handleForm} value={form.Subject || ''} variant="outlined" sx={{ mb: 2 }} />
          <Button
            className='btns'
            buttonStyle={submitting ? 'btn--disabled' : 'btn--primaryy'}
            buttonSize='btn--large'
            type="submit"
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'} <i className='far fa-play-circle' />
          </Button>
        </form>
      </Box>
    </Layout>
  );
};

export default Contact;
