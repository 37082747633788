import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import { BsFillTrashFill } from 'react-icons/bs';
import '../styles/Table.css';

const Customer_data = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('http://localhost:8080/demo');
      const data = await response.json();
      const usersWithStatus = data.map(user => ({ ...user, status: 'Pending' }));
      setUsers(usersWithStatus);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const toggleStatus = (index) => {
    const updatedUsers = [...users];
    updatedUsers[index].status = updatedUsers[index].status === 'Pending' ? 'Done' : 'Pending';
    setUsers(updatedUsers);

    // Update the status in localStorage
    localStorage.setItem(`status_${users[index]._id}`, updatedUsers[index].status);
  };

  const handleDelete = async (index) => {
    const userToDelete = users[index];

    try {
      await fetch(`http://localhost:8080/demo/${userToDelete._id}`, {
        method: 'DELETE',
      });

      const updatedUsers = users.filter((_, i) => i !== index);
      setUsers(updatedUsers);

      // Remove status from localStorage
      localStorage.removeItem(`status_${userToDelete._id}`);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  return (
    <Layout>
      <Box sx={{ my: 3, ml: 6, "& h4": { fontWeight: "bold", mb: 2 },minHeight: 600 }}>
        <Typography variant="h4" >Get In Touch</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell sx={{color:'white'}}>Name</TableCell>
                <TableCell sx={{color:'white'}}>Subject</TableCell>
                <TableCell sx={{color:'white'}}>Phone Number</TableCell>
                <TableCell sx={{color:'white'}}>Status</TableCell>
                <TableCell sx={{color:'white'}}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.Subject}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => toggleStatus(index)}
                      className={`status-button ${user.status.toLowerCase()}`}
                      style={{
                        color: user.status === 'Pending' ? 'red' : 'green',
                      }}
                    >
                      {user.status}
                    </Button>
                  </TableCell>
                  <TableCell>
                    <BsFillTrashFill
                      className="delete-icon"
                      onClick={() => handleDelete(index)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
};

export default Customer_data;
