import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import image1 from '../images/Hardware/M_track.jpeg';
import image2 from '../images/Hardware/Curtains Electric Motorized.jpeg';
import image3 from '../images/Hardware/RippleFold_track.jpeg';
import image4 from '../images/Hardware/28mm Eyelet_extendable.jpeg';
import image5 from '../images/Hardware/Rod.jpeg';



function Cards() {
  return (
    <div className='cards'>
      <h1>"Curtain Tracks and stainless steel curtain rod starting from 120 Rupees per feet"</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={image1}
              text='M Track for smooth curtain track movement '
              label='M Track'
            //   path='/services'
            />
            <CardItem
              src={image2}
              text='Electric Motorized curtain Track'
              label='Electric Motorized Track'
            //   path='/services'
            />
            <CardItem
              src={image3}
              text='Ripple fold curtain Track'
              label='Curtain Track'
            //   path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={image4}
              text='This Eyelet curtain extendables gives a beautiful look starting from 149 rupees per feet'
              label='Eyelet curtain extendables'
            //   path='/products'
            />
             <CardItem
              src={image5}
              text='Jindal stainless steel curtain rod starting from 120 rupees per feet '
              label='Jindal Rod'
            //   path='/products'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;