import React from "react";
import Layout from "./../components/Layout/Layout";
import "../styles/HomeStyles.css";
import Cards from "../components/Corporate_Cards";
import Herofour from "../components/Herofour";

const Home = () => {
  return (
    <Layout>
      <Herofour/>
      <Cards />
    </Layout>
  );
};

export default Home;