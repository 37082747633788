import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import image1 from '../images/Curtain_repairing.jpeg';
import image2 from '../images/Curtain_Movement.jpeg';
import image4 from '../images/zebra-blinds.jpg';
import image5 from '../images/roller-blinds.jpg';
import image6 from '../images/OIP.jpg';




function Cards() {
  return (
    <div className='cards'>
      <h1>
"We go easy on your pocket by reparing your curtains for starting from 100 rupees per panal"</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={image1}
              text='Your Curtain movement got stuck don not worry we got you'
              label='Curtain Movement Reparing'
            //   path='/services'
            />
            <CardItem
              src={image2}
              text='Hooks not working do not worry we will fix them '
              label='Hooks repairing'
            //   path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={image4}
              text='"Vertical Elegance – Style that Stands Tall. but got stuck we will fix that too"'
              label='Vertical Blinds'
            //   path='/products'
            />
            <CardItem
              src={image5}
              text='Sleek Simplicity– Style in Every Smooth Roll. but got stuck we will fix that too"'
              label='Roller Blinds'
            //   path='/products'
            />
            <CardItem
              src={image6}
              text='– Style in Every Smooth Roll. but got stuck we will fix that too"'
              label='Zebra Blinds'
            //   path='/products'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;