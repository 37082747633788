import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import image1 from '../images/Track/1.jpeg';
import image2 from '../images/Track/2.jpeg';
import image3 from '../images/Track/3.jpeg';
import image4 from '../images/Track/4.jpeg';
import image5 from '../images/Track/5.jpeg';



function Cards() {
  return (
    <div className='cards'>
      <h1>"Track installation services starting from just 200 rupees per window "</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={image1}
              text='Experience the allure of luxurious track and intricate detailing'
              label='M track installation'
            //   path='/services'
            />
            <CardItem
              src={image2}
              text='Dual track installation for Main and sheer curtains'
              label='Dual track '
            //   path='/products'
            />
             <CardItem
              src={image3}
              text='Track installation on cealing to give you are curtains best look'
              label='Cealing Track'
            //   path='/products'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={image4}
              text='"Design Your Dreams: we fix you dream curtains with curtain rod installation"'
              label='Eyelet Curtains'
            //   path='/services'
            />
            <CardItem
              src={image5}
              text='Electric Moterized track for seamless movement'
              label='Electric Moterized '
            //   path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;