import React from "react";
import Layout from "../components/Layout/Layout";
import "../styles/HomeStyles.css";
import HeroSection from "../components/HeroSection";
import Cards from "../components/Cards";
import Gallary from "../components/Gallary";
import Slider from "../components/Slider"; // Fix the import here

const Home = () => {
  return (
    <Layout>
      <HeroSection/>
      <Cards />
      <h1> Gallary </h1>
      <Gallary/>
    </Layout>
  );
};

export default Home;
