import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import image5 from '../images/contact.jpg';

function Cards() {
  return (
    <div className='cards'>
      <h1>Get In Touch</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
          <CardItem
              src={image5}
              text='Our The Curtain Company executive will be pleased to visit your residence
              to discuss your unique decor requirements and offer expert design insights.
              During this visit, our dedicated executive will attentively listen to your
              preferences and gather essential details to tailor personalized decor solutions
              to your space. We are committed to providing you with exceptional service and
              creative decor ideas that align with your vision.'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;