import React from "react";
import Layout from "./../components/Layout/Layout";
import "../styles/HomeStyles.css";
import Cards from "../components/Bedroom_Cards";
import Herothree from "../components/Herothree";

const Home = () => {
  return (
    <Layout>
      <Herothree/>
      <Cards />
    </Layout>
  );
};

export default Home;