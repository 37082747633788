import React, { useState } from "react";
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink } from "react-router-dom";
import "../../styles/HeaderStyles.css";
import StorefrontIcon from '@mui/icons-material/Storefront';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {  useNavigate } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const SelectVariants=()=> {
  const [age, setAge] = React.useState("");
  const theme = useTheme(); 
  const navigate = useNavigate();
  const handleChange = (event) => {
    setAge(event.target.value);
    navigate(`/${event.target.value}`)
  };

  return (
    <div >
      <FormControl  variant="standard" sx={{ m: 1, 
        color: "#cf21ac", // Change the color here
        "&.Mui-focused": {
          color: "#E363AA", // Change the focused color here
        },minWidth: 100,padding: '7px',margin: '-10px'}}>
        <InputLabel id="demo-simple-select-standard-label" 
        shrink={false}
        sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
           
        }}>Services</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={age}
          onChange={handleChange}
          label="Age"
          sx={{
            color: "#cf21ac", // Change the color here
            "&.Mui-focused": {
              color: "#E363AA", // Change the focused color here
            },
            padding: '-10px',marginTop: '0px'
          }}
        >
          <MenuItem value="Curtain" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Curtains Stiching</MenuItem>
          <MenuItem value="Sofa" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Hardware</MenuItem>
          <MenuItem value="Bedroom" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Track Installation</MenuItem>
          <MenuItem value="Corporate" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Curtain Reparing</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

const SelectVariantsm=()=> {
  const [age, setAge] = React.useState("");
  const theme = useTheme(); 
  const navigate = useNavigate();
  const handleChange = (event) => {
    setAge(event.target.value);
    navigate(`/${event.target.value}`)
  };

  return (
    <div>
      <FormControl  variant="standard" sx={{ m: 1, 
        color: "#cf21ac", // Change the color here
        "&.Mui-focused": {
          color: "#E363AA", // Change the focused color here
        },minWidth: 100,padding: '-2px',margin: '-10px',borderTop: '0px'}}>
        <InputLabel id="demo-simple-select-standard-label" 
        shrink={false}
        sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Services</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={age}
          onChange={handleChange}
          label="Age"
          sx={{
            color: "#cf21ac", // Change the color here
            "&.Mui-focused": {
              color: "#E363AA", // Change the focused color here
            },
          }}
        >
          <MenuItem value="Curtain" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Curtains Stiching</MenuItem>
          <MenuItem value="Hardware" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Hardware</MenuItem>
          <MenuItem value="Track" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Track Installation</MenuItem>
          <MenuItem value="repair" sx={{
          color: "#cf21ac", // Change the color here
          "&.Mui-focused": {
            color: "#E363AA", // Change the focused color here
          },
        }}>Curtain Repairing</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  // hndle menu click
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  //menu drawer
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <StorefrontIcon sx={{ color: '#cf21ac' }} />
      <Typography
        color={"#cf21ac"}
        variant="h6"
        component="div"
        sx={{ flexGrow: 1, my: 2 }}
      >
         The Curtain Company
        
      </Typography>
      
      <Divider />
      <ul className="mobile-navigation">
        <li>
          <NavLink activeClassName="active" to={"/"}>
            Home
          </NavLink>
        </li>
        <li>
                <NavLink to={"/Curtain"}>Curtain Stiching</NavLink>
                </li>
                <li>
                  <NavLink to={"/Sofa"}>Hardware</NavLink>
                </li>
                <li>
                  <NavLink to={"/Bedroom"}>Track Installation</NavLink>
                </li>
                <li>
                  <NavLink to={"/Corporate"}>Curtain Repairing</NavLink>
                </li>
      </ul>
    </Box>
  );
  return (
    <>
      <Box>
        <AppBar component={"nav"} sx={{ bgcolor: "#ffffff" }}>
          <Toolbar>
            <IconButton
              color="#cf21ac"
              aria-label="open drawer"
              edge="start"
              sx={{
                mr: 2,
                display: { sm: "none" },
              }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon sx={{ color: '#cf21ac' }}/>
            </IconButton>
            <StorefrontIcon sx={{ color: '#cf21ac' }}/>
            <Typography
              color={"#cf21ac"}
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
            >
               The Curtain Company
            </Typography>

            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <ul className="navigation-menu">
                <li>
                  <NavLink activeClassName="active" to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li>
                <NavLink to={"/Curtain"}>Curtain Stiching</NavLink>
                </li>
                <li>
                  <NavLink to={"/Sofa"}>Hardware</NavLink>
                </li>
                <li>
                  <NavLink to={"/Bedroom"}>Track Installation</NavLink>
                </li>
                <li>
                  <NavLink to={"/Corporate"}>Curtain Repairing</NavLink>
                </li>
              </ul>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "240px",
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
        <Box>
          <Toolbar />
        </Box>
      </Box>
    </>
  );
};
{/* <SelectVariantsm/> */}
export default Header;
